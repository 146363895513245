import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { callBackend } from "../components/callBackend";
import { Container } from "react-bootstrap";
import DataTable from "../components/DataTable";
import EventsData from "../data/events.json";
import { toast } from "react-toastify";
import DownloadButton from "../components/DownloadButton";

export default function EventRegData() {
	const { eid } = useParams();

	const [count, setCount] = useState(0);
	const [partData, setPartData] = useState([]);
	const [role, setRole] = useState(0);

	let pref = JSON.parse(localStorage.getItem("nssc-admin-pref"));
	const [tableControls, setTableControls] = useState(
		pref && pref.pageID == 10 + Number(eid)
			? pref
			: {
				page: 1,
				length: 20,
				sortField: "_id",
				sortDir: 1,
			}
	);

	let columns =
		eid <= 3
			? {
				_id: "Sl. No.",
				pid: "PID",
				fName: "First Name",
				lName: "Last Name",
				college: "College/School",
				email: "Email",
				roll: "De-Reg",
			}
			: {
				_id: "Sl. No.",
				teamID: "Team ID",
				teamName: "Team Name",
				pids: "Participant IDs",
				eventID: "De-Reg",
			};

	let downloadHeaders = [
		{ label: "PID", key: "pid" },
		{ label: "First Name", key: "fName" },
		{ label: "Last Name", key: "lName" },
		{ label: "Email Address", key: "email" },
		{ label: "Phone Number", key: "phone" },
		{ label: "Gender", key: "gender" },
		{ label: "Level of Study", key: "level" },
		{ label: "Name of Institution", key: "college" },
		{ label: "Payment Status", key: "paymentStatus" },
	];

	if (eid > 3) {
		downloadHeaders.push({ label: "Team ID", key: "teamID" });
		downloadHeaders.push({ label: "Team Name", key: "teamName" });
	}

	if (EventsData[eid].isSub) {
		if (eid <= 3) {
			columns = {
				...columns,
				events: eid == 2 ? "Hackerank Username" : "Submission",
			};
			downloadHeaders.push({ label: "Submission", key: "submission" });
		} else {
			columns = {
				...columns,
				subLink: "Submission Link",
			};
			downloadHeaders.push({ label: "Submission", key: "subLink" });
		}
	}

	const getPartData = () => {
		if (!localStorage.getItem("nssc-admin-token")) {
			window.location.href = "/login";
		}
		callBackend({
			method: "GET",
			endpoint: "/admin/events/" + eid + "/",
			data:
				"sortField=" +
				tableControls.sortField +
				"&sortDir=" +
				tableControls.sortDir +
				"&first=" +
				(0 + tableControls.length * (tableControls.page - 1)) +
				"&limit=" +
				tableControls.length,
			onSuccess: (res) => {
				setPartData(res.data);
				setCount(res.count);
				setRole(res.role);
			},
		});
	};

	const deregister = async (pid) => {
		if (role < 2) {
			toast.error("You are not authorized to perform this action", {
				position: "top-center",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
			});
			return;
		}

		if (confirm("Are you sure you want to deregister?")) {
			callBackend({
				method: "POST",
				endpoint: "/admin/events/delete/",
				data: {
					eid: eid,
					pid: pid,
				},
				onSuccess: (res) => {
					getPartData();
				},
			});
		}
	};

	useEffect(() => {
		setTableControls(
			pref && pref.pageID == 10 + Number(eid)
				? pref
				: {
					page: 1,
					length: 20,
					sortField: "_id",
					sortDir: 1,
				}
		);
		getPartData();
	}, [eid]);

	useEffect(() => {
		getPartData();
		localStorage.setItem(
			"nssc-admin-pref",
			JSON.stringify({ ...tableControls, pageID: 10 + Number(eid) })
		);
	}, [tableControls]);

	return (
		<Container
			style={{
				marginTop: "20px",
				marginBottom: "20px",
			}}
		>
			<h1>{EventsData[eid].name}</h1>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					gap: "20px",
				}}
			>
				<p>
					{EventsData[eid].isSub
						? "Deadline for Submission: " + EventsData[eid].deadline
						: "Date of Event: " + EventsData[eid].deadline}
				</p>
				{EventsData[eid].team && (
					<p>
						Max team size : {EventsData[eid].max} | Min team size :{" "}
						{EventsData[eid].min}
					</p>
				)}
			</div>
			<DataTable
				eid={eid}
				columns={columns}
				data={partData}
				count={count}
				tableControls={tableControls}
				setTableControls={setTableControls}
				deregister={deregister}
				downloadButton={
					<DownloadButton
						endpoint={"/admin/download/event/" + eid + "/"}
						downloadHeaders={downloadHeaders}
					/>
				}
			/>
		</Container>
	);
}
