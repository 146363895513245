import { useState, useEffect } from "react";
import { callBackend } from "../components/callBackend";
import { Container } from "react-bootstrap";
import DataTable from "../components/DataTable";
import DownloadButton from "../components/DownloadButton";

export default function AllRegData() {
	const [count, setCount] = useState(0);
	const [partData, setPartData] = useState([]);

	let pref = JSON.parse(localStorage.getItem("nssc-admin-pref"));
	const [tableControls, setTableControls] = useState(
		pref && pref.pageID == 1
			? pref
			: {
					page: 1,
					length: 20,
					sortField: "_id",
					sortDir: 1,
			  }
	);

	const columns = {
		_id: "Serial Number",
		pid: "PID",
		fName: "First Name",
		lName: "Last Name",
		college: "College/School",
		city: "City",
		state: "State/UT",
		paymentStatus: "Payment Status",
	};

	let downloadHeaders = [
		{ label: "PID", key: "pid" },
		{ label: "First Name", key: "fName" },
		{ label: "Last Name", key: "lName" },
		{ label: "Email Address", key: "email" },
		{ label: "Phone Number", key: "phone" },
		{ label: "Gender", key: "gender" },
		{ label: "Date of Birth", key: "dob" },
		{ label: "Level of Study", key: "level" },
		{ label: "Name of Institution", key: "college" },
		{ label: "City", key: "city" },
		{ label: "State/UT", key: "state" },
		{ label: "Payment Status", key: "paymentStatus" },
	];

	const getPartData = async () => {
		if (!localStorage.getItem("nssc-admin-token")) {
			window.location.href = "/login";
		}

		let search = document.getElementsByName("search")[0].value;
		let searchField = document.getElementsByName("searchField")[0].value;

		callBackend({
			method: "GET",
			endpoint: "/admin/reg/",
			data:
				"sortField=" +
				tableControls.sortField +
				"&sortDir=" +
				tableControls.sortDir +
				"&first=" +
				(0 + tableControls.length * (tableControls.page - 1)) +
				"&limit=" +
				tableControls.length +
				"&search=" +
				search +
				"&searchField=" +
				searchField,
			onSuccess: (res) => {
				setPartData(res.data);
				setCount(res.count);
			},
		});
	};

	useEffect(() => {
		getPartData();
		localStorage.setItem(
			"nssc-admin-pref",
			JSON.stringify({ ...tableControls, pageID: 1 })
		);
	}, [tableControls]);

	return (
		<Container
			style={{
				marginTop: "20px",
				marginBottom: "20px",
			}}
		>
			<h1>NSSC 2024 Website Registrations</h1>
			Search by:&nbsp;&nbsp;
			<select
				name="searchField"
				onChange={() => {
					getPartData();
					setTableControls({
						...tableControls,
						page: 1,
					});
				}}
			>
				<option value="pid">PID</option>
				<option value="fName">First Name</option>
				<option value="lName">Last Name</option>
				<option value="college">College/School</option>
			</select>
			&nbsp;&nbsp;Value:&nbsp;&nbsp;
			<input
				type="text"
				name="search"
				placeholder="Search"
				onChange={() => {
					getPartData();
					setTableControls({
						...tableControls,
						page: 1,
					});
				}}
			/>
			<br />
			<br />
			<DataTable
				columns={columns}
				data={partData}
				count={count}
				tableControls={tableControls}
				setTableControls={setTableControls}
				downloadButton={
					<DownloadButton
						endpoint={"/admin/download/reg/"}
						downloadHeaders={downloadHeaders}
					/>
				}
			/>
		</Container>
	);
}
