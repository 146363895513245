import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { callBackend } from "../components/callBackend";

export default function Login() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const toastDict = {
		position: "top-center",
		autoClose: 3000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "dark",
	};

	useEffect(() => {
		if (localStorage.getItem("nssc-admin-token")) {
			navigate("/");
		}
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (email && password) {
			callBackend({
				method: "POST",
				endpoint: "/admin/login",
				setLoading,
				data: { email, password },
				onSuccess: (res) => {
					localStorage.setItem("nssc-admin-token", res.token);
					// if (res.role < 2) navigate("/sa");
					// else
					navigate("/");
				},
			});
		} else {
			toast.error("Please fill all the fields", toastDict);
		}
	};

	return (
		<Container
			style={{
				backgroundColor: "#fff",
				zIndex: 100,
				height: "100vh",
				position: "absolute",
				top: 0,
			}}
			fluid
		>
			<Form
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%,-50%)",
				}}
				onSubmit={(e) => {
					handleSubmit(e);
				}}
			>
				<Form.Group className="mb-3" controlId="formBasicEmail">
					<Form.Label>Email address</Form.Label>
					<Form.Control
						type="email"
						placeholder="Enter email"
						value={email}
						onChange={(e) => {
							setEmail(e.target.value);
						}}
					/>
				</Form.Group>
				<Form.Group className="mb-3" controlId="formBasicPassword">
					<Form.Label>Password</Form.Label>
					<Form.Control
						type="password"
						placeholder="Password"
						value={password}
						onChange={(e) => {
							setPassword(e.target.value);
						}}
					/>
				</Form.Group>
				<Button variant="primary" type="submit">
					{loading ? (
						<Spinner animation="border" size="sm" />
					) : (
						"Login"
					)}
				</Button>
			</Form>
		</Container>
	);
}
