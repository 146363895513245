import React, { useEffect, useState } from "react";
import { callBackend } from "../components/callBackend";
import { Container, Form } from "react-bootstrap";
import DataTable from "../components/DataTable";
import DownloadButton from "../components/DownloadButton";
import EventData from "../data/events.json";

const Halls = () => {
	const [hall, setHall] = useState("all");
	const [event, setEvents] = useState("all");
	const [participants, setParticipants] = useState([]);
	const [filteredParticipants, setFilteredParticipants] = useState([]);
	const [tableControls, setTableControls] = useState({
		page: 1,
		length: 20,
		sortField: "_id",
		sortDir: 1,
	});

	const columns = {
		_id: "Serial Number",
		pid: "PID",
		fName: "First Name",
		lName: "Last Name",
		college: "College/School",
		city: "City",
		state: "State/UT",
		hall: "Hall",
	};

	let downloadHeaders = [
		{ label: "PID", key: "pid" },
		{ label: "First Name", key: "fName" },
		{ label: "Last Name", key: "lName" },
		{ label: "Email Address", key: "email" },
		{ label: "Phone Number", key: "phone" },
		{ label: "Gender", key: "gender" },
		{ label: "Date of Birth", key: "dob" },
		{ label: "Name of Institution", key: "college" },
		{ label: "City", key: "city" },
		{ label: "State/UT", key: "state" },
		{ label: "Hall", key: "hall" },
	];

	useEffect(() => {
		// Fetch participants data from backend
		callBackend({
			method: "GET",
			endpoint: "/admin/reportedParticipants/" + hall,
			onSuccess: (data) => {
				console.log(data);
				setParticipants(data.data);
				setFilteredParticipants(data.data);
			},
			onError: (error) => console.error("Error fetching participants:", error),
		});
	}, [hall]);

	const handleEventFilter = (event) => {
		console.log(event);
		if (event === "all") {
			setFilteredParticipants(participants);
			return;
		}
		setFilteredParticipants(
			participants.filter((participant) => {
				if (event <= 3) {
					return participant.events[event].status;
				} else {
					return participant.events[event];
				}
			})
		);
	};

	useEffect(() => {
		if (event) {
			handleEventFilter(event);
		}
	}, [event, hall]);

	const hallOptions = {
		all: "All halls",
		N: "No Accomodation",
		MMM: "MMM (50)",
		GKH: "Gokhale (30)",
		AZAD: "Azad (NA)",
		PAT: "Patel (NA)",
		BCR: "BC Roy (30)",
		HJB: "HJB (35)",
		JCB: "JCB (75)",
		LLR: "LLR (42)",
		BRH: "BRH (NA)",
		SNVH: "SNVH (55)",
		RLB: "RLB (45)",
		NH: "Nehru (50)",
	};

	const eventOptions = {
		all: { name: "All events" },
		...EventData,
	};

	const getTotalCapacity = () => {
		return Object.values(hallOptions).reduce((total, option) => {
			if (option == "No Accomodation" || option == "All halls") return total;
			const match = option.match(/\((\d+)\)/);
			return total + (match ? parseInt(match[1], 10) : 0);
		}, 0);
	};

	return (
		<Container style={{ marginTop: "20px", marginBottom: "20px" }}>
			<h1 className="text-center">Halls</h1>
			<Form.Group controlId="hallSelect">
				<Form.Control
					as="select"
					value={hall}
					onChange={(e) => {
						setHall(e.target.value);
					}}
				>
					{Object.entries(hallOptions).map(([key, value]) => (
						<option key={key} value={key}>
							{key === "all" ? `All halls (${getTotalCapacity()})` : value}
						</option>
					))}
				</Form.Control>
				<Form.Control
					as="select"
					value={event}
					onChange={(e) => {
						setEvents(e.target.value.name);
						handleEventFilter(e.target.value);
					}}
				>
					{Object.entries(eventOptions).map(([key, value]) => (
						<option key={key} value={key}>
							{value.name}
						</option>
					))}
				</Form.Control>
			</Form.Group>
			<div style={{ marginTop: "2rem" }}>
				<DataTable
					columns={columns}
					data={filteredParticipants}
					count={filteredParticipants.length}
					tableControls={tableControls}
					setTableControls={setTableControls}
					downloadButton={
						<DownloadButton
							endpoint={"/admin/reportedParticipants/" + hall}
							downloadHeaders={downloadHeaders}
						/>
					}
				/>
			</div>
		</Container>
	);
};

export default Halls;
