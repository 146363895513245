import { useState, useEffect } from "react";
import { callBackend } from "../components/callBackend";
import { Container } from "react-bootstrap";
import DataTable from "../components/DataTable";
import DownloadButton from "../components/DownloadButton";


export default function AllPayments() {
	const [count, setCount] = useState(0);
	const [partData, setPartData] = useState([]);

	let pref = JSON.parse(localStorage.getItem("nssc-admin-pref"));
	const [tableControls, setTableControls] = useState(
		pref && pref.pageID == 4
			? pref
			: {
					page: 1,
					length: 20,
					sortField: "_id",
					sortDir: 1,
			  }
	);

	const columns = {
		_id: "Serial Number",
		pid: "PID",
		fName: "First Name",
		lName: "Last Name",
		college: "College/School",
		payment: "Payment Date Time",
	};

	let downloadHeaders = [
		{ label: "PID", key: "pid" },
		{ label: "First Name", key: "fName" },
		{ label: "Last Name", key: "lName" },
		{ label: "Email Address", key: "email" },
		{ label: "Phone Number", key: "phone" },
		{ label: "Gender", key: "gender" },
		{ label: "Date of Birth", key: "dob" },
		{ label: "Level of Study", key: "level" },
		{ label: "Name of Institution", key: "college" },
		{ label: "City", key: "city" },
		{ label: "State/UT", key: "state" },
		{ label: "Payment Date Time", key: "paymentTime" },
		{ label: "T-Shirt Size", key: "tShirt" },
	];

	useEffect(() => {
		if (!localStorage.getItem("nssc-admin-token")) {
			window.location.href = "/login";
		}
		callBackend({
			method: "GET",
			endpoint: "/admin/payments/",
			data:
				"sortField=" +
				tableControls.sortField +
				"&sortDir=" +
				tableControls.sortDir +
				"&first=" +
				(0 + tableControls.length * (tableControls.page - 1)) +
				"&limit=" +
				tableControls.length,
			onSuccess: (res) => {
				setPartData(res.data);
				setCount(res.count);
			},
			
		});
		localStorage.setItem(
			"nssc-admin-pref",
			JSON.stringify({ ...tableControls, pageID: 4 })
		);
	}, [tableControls]);

	return (
		<Container
			style={{
				marginTop: "20px",
				marginBottom: "20px",
			}}
		>
			<h1>NSSC 2024 Payments</h1>
			<DataTable
				columns={columns}
				data={partData}
				count={count}
				tableControls={tableControls}
				setTableControls={setTableControls}
				downloadButton={
					<DownloadButton
						endpoint={"/admin/download/payments/"}
						downloadHeaders={downloadHeaders}
					/>
				}
			/>
		</Container>
	);
}
