import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Navbar from "./components/Navbar";
import AllRegData from "./pages/AllRegData";
import EventRegData from "./pages/EventRegData";
import ParticipantDetails from "./pages/ParticipantDetails";
import AllSAReg from "./pages/AllSAReg";
import SA from "./pages/SA";
import Login from "./pages/Login";
import SADetails from "./pages/SADetails";
import AllPayments from "./pages/AllPayments";
import Attendance from "./pages/Attendance";
import Halls from "./pages/Halls";
import Winners from "./pages/Winner";

function App() {
	return (
		<>
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="dark"
			/>
			<Router>
				<Navbar />
				<Routes>
					<Route exact path="/" element={<AllRegData />} />
					<Route path="login" element={<Login />} />
					<Route path="/event/:eid/" element={<EventRegData />} />
					<Route
						path="/participant/:pid/"
						element={<ParticipantDetails />}
					/>
					<Route path="/sa/" element={<SA />} />
					<Route exact path="/sa/reg/" element={<AllSAReg />} />
					<Route path="/sa/:saID/" element={<SADetails />} />
					<Route path="/payments" element={<AllPayments />} />
					<Route
						path="gl/attendance/:gl/"
						element={<Attendance glOrEvent={"gl"} />}
					/>
					<Route
						path="event/attendance/:gl/"
						element={<Attendance glOrEvent={"event"} />}
					/>
					<Route path="winners" element={<Winners />} />
					<Route path="halls" element={<Halls />} />
					<Route path="*" element={<h1>404</h1>} />
				</Routes>
			</Router>
		</>
	);
}

export default App;
