import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { callBackend } from "../components/callBackend";
import { Button, Container } from "react-bootstrap";
import EventsData from "../data/events.json";
import GLData from "../data/gl.json";
import { toast } from "react-toastify";

export default function ParticipantDetails() {
	const { pid } = useParams();
	const [role, setRole] = useState(0);
	const [message, setMessage] = useState("");

	const [participant, setParticipant] = useState(null);

	const [paymentUpdate, setPaymentUpdate] = useState({
		pid: pid,
		size: "",
	});

	const [hall, setHall] = useState("");

	const toastDict = {
		position: "top-center",
		autoClose: 3000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "dark",
	};

	const getDetails = async () => {
		if (!localStorage.getItem("nssc-admin-token")) {
			window.location.href = "/login";
		}
		callBackend({
			method: "GET",
			endpoint: "/admin/reg/" + pid,
			onSuccess: (res) => {
				setParticipant(res.data);
				setHall(res.data.hall);
				setRole(res.role);
			},
		});
	};

	const getTShirt = async () => {
		if (!localStorage.getItem("nssc-admin-token")) {
			window.location.href = "/login";
		}
		callBackend({
			method: "GET",
			endpoint: "/admin/reg/tshirt/" + pid,
			onSuccess: (res) => {
				if (res.data == 0) {
					setMessage("");
				} else if (res.data == 1) {
					setMessage("T-Shirt Not Required");
				} else if (res.data == 2) {
					setMessage("T-Shirt Not Given");
				} else if (res.data == 3) {
					setMessage("T-Shirt Given");
				} else {
					console.error("Error");
				}
			},
		});
	};

	const deregister = async (eid) => {
		if (role < 2) {
			toast.error("You are not authorized to perform this action!", toastDict);
			return;
		}

		if (confirm("Are you sure you want to deregister?")) {
			callBackend({
				method: "POST",
				endpoint: "/admin/events/delete/",
				data: {
					eid: eid,
					pid: pid,
				},
				onSuccess: (res) => {
					getDetails();
				},
			});
		}
	};

	const glAttendance = async (gl) => {
		if (role < 2) {
			toast.error("You are not authorized to perform this action", toastDict);
			return;
		}

		if (confirm("Are you sure you want to mark attendance?")) {
			callBackend({
				method: "POST",
				endpoint: "/admin/gl/" + gl,
				data: {
					pid: pid,
				},
				onSuccess: (res) => {
					getDetails();
				},
			});
		}
	};

	const eventAttendance = async (eid) => {
		if (role < 2) {
			toast.error("You are not authorized to perform this action", toastDict);
			return;
		}

		if (confirm("Are you sure you want to mark attendance?")) {
			callBackend({
				method: "POST",
				endpoint: "/admin/event/attendance/" + eid,
				data: {
					pid: pid,
				},
				onSuccess: (res) => {
					getDetails();
				},
			});
		}
	};

	const tShirtGiving = async () => {
		if (role < 3) {
			toast.error("You are not authorized to perform this action", toastDict);
			return;
		}

		if (participant.payment.status < 1) return;
		if (participant.tShirt == null) return;

		if (confirm("Have you given the correct T-Shirt to the participant?")) {
			callBackend({
				method: "POST",
				endpoint: "/admin/reg/tshirt/" + pid,
				data: {},
				onSuccess: (res) => {
					getDetails();
					getTShirt();
				},
			});
		}
	};

	const reportParticipant = async () => {
		if (role < 3) {
			toast.error("You are not authorized to perform this action", toastDict);
			return;
		}

		if (
			confirm(
				"Are you sure you want to mark participant reporting at reg desk?"
			)
		) {
			callBackend({
				method: "POST",
				endpoint: "/admin/reg/attendance/" + pid,
				data: {},
				onSuccess: (res) => {
					getDetails();
					tShirtGiving();
				},
			});
		}
	};

	const updatePayment = async () => {
		if (role < 3) {
			toast.error("You are not authorized to perform this action", toastDict);
			return;
		}

		if (
			confirm("Are you sure you want to confirm payment for this participant?")
		) {
			callBackend({
				method: "POST",
				endpoint: "/admin/payment/",
				data: paymentUpdate,
				onSuccess: (res) => {
					getDetails();
				},
			});
		}
	};

	const regDesk = participant ? (
		participant.payment.status == 2 ? (
			<div
				style={{
					marginBlock: "1rem",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexWrap: "wrap",
					gap: "10px",
				}}
			>
				<b style={{ color: "green" }}>
					Partcipant Reported {message ? " | " + message : ""}
				</b>
				{role >= 3 &&
					participant.payment.status == 2 &&
					participant.tShirt != null &&
					participant.tShirtGiven == false && (
						<button className="btn btn-primary" onClick={tShirtGiving}>
							Mark T-Shirt Given
						</button>
					)}
			</div>
		) : role >= 3 ? (
			<button className="btn btn-primary" onClick={reportParticipant}>
				Report at RegDesk
			</button>
		) : (
			<b style={{ color: "red" }}>Participant not reported</b>
		)
	) : null;

	const paymentBox = participant ? (
		<div
			style={{
				fontWeight: "bold",
				color: participant.payment.status >= 1 ? "green" : "red",
			}}
		>
			{participant.payment.status >= 1 ? (
				"Payment on " +
				participant.payment.time +
				" | Size : " +
				(participant.tShirt ? participant.tShirt : "No T-Shirt")
			) : (
				<div
					style={{
						float: "right",
						marginLeft: "10px",
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						flexWrap: "wrap",
						gap: "10px",
						marginBottom: "10px",
					}}
				>
					Payment Pending
					{role >= 3 && (
						<>
							<select
								onChange={(e) => {
									setPaymentUpdate({
										...paymentUpdate,
										size: e.target.value,
									});
								}}
							>
								<option value="">Select T-Shirt Size</option>
								<option value="N">No TShirt</option>
								<option value="S">S</option>
								<option value="M">M</option>
								<option value="L">L</option>
								<option value="XL">XL</option>
								<option value="XXL">XXL</option>
							</select>
							<Button variant="primary" onClick={updatePayment}>
								Update Payment
							</Button>
						</>
					)}
				</div>
			)}
		</div>
	) : null;

	const hallBox = participant ? (
		<div
			style={{
				marginBlock: "1rem",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexWrap: "wrap",
				gap: "10px",
			}}
		>
			<h5>Hall : {participant.hall ? participant.hall : "Not Assigned"}</h5>
			{role >= 3 && (
				<>
					<select
						onChange={(e) => {
							setHall(e.target.value);
						}}
						value={hall}
					>
						<option value="">Select Hall</option>
						<option value="N">No Accomodation</option>
						<option value="MMM">MMM</option>
						<option value="GKH">Gokhale</option>
						<option value="AZAD">Azad</option>
						<option value="PAT">Patel</option>
						<option value="BCR">BC Roy</option>
						<option value="HJB">HJB</option>
						<option value="JCB">JCB</option>
						<option value="LLR">LLR</option>
						<option value="BRH">BRH</option>
						<option value="SNVH">SNVH</option>
						<option value="RLB">RLB</option>
						<option value="NH">Nehru</option>
					</select>
					<button
						className="btn btn-primary"
						onClick={() => {
							callBackend({
								method: "POST",
								endpoint: "/admin/hall/",
								data: {
									hall: hall,
									pid: pid,
								},
								onSuccess: (res) => {
									getDetails();
								},
							});
						}}
					>
						Update Hall
					</button>
				</>
			)}
		</div>
	) : null;

	useEffect(() => {
		getDetails();
		getTShirt();
	}, [pid]);

	const handleUserLogin = () => {
		if (!localStorage.getItem("nssc-admin-token")) {
			window.location.href = "/login";
		}
		callBackend({
			method: "POST",
			endpoint: "/admin/loginUser",
			data: {
				pid: pid,
			},
			onSuccess: (res) => {
				const token = res.token;
				const adminToken = localStorage.getItem("nssc-admin-token");
				window.open(
					"https://nssc.in/login?token=" + token + "&admin=" + adminToken,
					"_blank"
				);
			},
		});
	};

	return (
		<Container
			style={{
				marginTop: "20px",
				marginBottom: "20px",
			}}
		>
			<h1 className="text-center">
				<u>{participant && participant.fName + " " + participant.lName}</u>
			</h1>
			{participant && (
				<div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							flexWrap: "wrap",
						}}
					>
						<h2>PID - {pid}</h2>
						{paymentBox}
						{regDesk}
					</div>
					{hallBox}
					<div style={{ width: "100%", textAlign: "center", margin: "20px" }}>
						<button
							type="submit"
							className="ms-2 btn btn-danger"
							onClick={handleUserLogin}
						>
							Login as User
						</button>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							flexWrap: "wrap",
						}}
					>
						<div>
							<p>
								Email :{" "}
								<a href={"mailto:" + participant.email}>{participant.email}</a>
							</p>
							<p>
								Phone :{" "}
								<a href={"tel:" + participant.phone}>{participant.phone}</a>
							</p>
							<p>
								Gender : {participant.gender} | Date of Birth -{" "}
								{participant.dob}
							</p>
						</div>
						<div>
							<p>College : {participant.college}</p>
							<p>Level : {participant.level}</p>
							<p>
								City/State : {participant.city}, {participant.state}
							</p>
						</div>
						<div>
							<p>Roll Number : {participant.roll}</p>
							<p>
								Student Ambassador :{" "}
								<Link to={"/sa/" + participant.saID}>{participant.saID}</Link>
							</p>
							<p>
								Verification Status :{" "}
								{participant.verified.status ? "Verified" : "Not Verified"}
							</p>
						</div>
					</div>
					<h3>Events</h3>
					<div
						style={{
							display: "grid",
							gridTemplateColumns: "1fr 1fr 1fr",
						}}
					>
						{Object.keys(participant.events).map((event) => {
							return (
								<div
									key={event}
									style={{
										margin: "5px",
										padding: "5px",
										border: "1px solid black",
										borderRadius: "5px",
										display: "flex",
										flexDirection: "column",
										justifyContent: "space-between",
									}}
								>
									{event <= 3 ? (
										<p>
											{event}. {EventsData[event].name}
											<br />
											{participant.events[event].status == 2
												? "Submitted"
												: participant.events[event].status == 1
												? "Registered"
												: "Not Registered"}
											{participant.events[event].status == 2 && (
												<>
													<br />
													{event == 2 ? (
														"Hackerank Username - " +
														participant.events[event].submission
													) : (
														<a
															href={participant.events[event].submission}
															target="_blank"
															rel="noreferrer"
														>
															Submission
														</a>
													)}
												</>
											)}
											{participant.events[event].certificates && (
												<ul>
													{participant.events[event].certificates.map(
														(cert) => (
															<li>
																<a
																	href={
																		"https://nssc.in/certificate/" + cert.cid
																	}
																	target="_blank"
																>
																	{cert.issuedFor.name === "winner"
																		? "Winner "
																		: "Participation "}
																	Certificate
																</a>
															</li>
														)
													)}
												</ul>
											)}
											{participant.events[event].status != 0 && !(role < 2) && (
												<>
													<br />
													<div
														style={{
															display: "flex",
															justifyContent: "space-between",
														}}
													>
														<Button
															variant="danger"
															onClick={() => {
																deregister(event);
															}}
															style={{
																width: "40%",
															}}
														>
															De-Regsiter
														</Button>
														<Button
															variant="primary"
															onClick={() => {
																eventAttendance(event);
															}}
															style={{
																width: "40%",
															}}
														>
															Mark Attendance
														</Button>
													</div>
												</>
											)}
										</p>
									) : (
										<p>
											{event}. {EventsData[event].name}
											{participant.events[event] ? (
												<>
													<br />
													Team ID - {participant.events[event].teamID}
													<br />
													Team Name - {participant.events[event].teamName}
													<br />
													PIDS -{" "}
													{participant.events[event].pids.map((pid) => (
														<Link
															key={pid}
															to={"/participant/" + pid}
															style={{
																marginRight: "5px",
															}}
														>
															{pid}
														</Link>
													))}
													<br />
													{participant.events[event].subLink ? (
														<a
															href={participant.events[event].subLink}
															target="_blank"
															rel="noreferrer"
														>
															Submission
														</a>
													) : (
														"Not Submitted"
													)}
													{participant.events[event].certificates && (
														<ul>
															{participant.events[event].certificates.map(
																(cert) => (
																	<li>
																		<a
																			href={
																				"https://nssc.in/certificate/" +
																				cert.cid
																			}
																			target="_blank"
																		>
																			{cert.issuedFor.name === "winner"
																				? "Winner "
																				: "Participation "}
																			Certificate
																		</a>
																	</li>
																)
															)}
														</ul>
													)}
													{!(role < 2) && (
														<>
															<div
																style={{
																	display: "flex",
																	justifyContent: "space-between",
																}}
															>
																<Button
																	variant="danger"
																	onClick={() => {
																		deregister(event);
																	}}
																	style={{
																		width: "40%",
																	}}
																>
																	De-Regsiter
																</Button>
																<Button
																	variant="primary"
																	onClick={() => {
																		eventAttendance(event);
																	}}
																	style={{
																		width: "40%",
																	}}
																>
																	Mark Attendance
																</Button>
															</div>
														</>
													)}
												</>
											) : (
												" - No Team Registered"
											)}
										</p>
									)}
								</div>
							);
						})}
					</div>
					<br />
					<h3>Guest Lectures and Workshops</h3>
					<div
						style={{
							display: "grid",
							gridTemplateColumns: "1fr 1fr 1fr",
						}}
					>
						{Object.keys(participant.gl).map((gl) => {
							if (gl > 8 || gl == 3) {
								return null;
							}
							if (GLData[gl] == null) {
								return null;
							}

							return (
								<div
									key={gl}
									style={{
										margin: "5px",
										padding: "5px",
										border: "1px solid black",
										borderRadius: "5px",
									}}
								>
									<p>
										{gl}. {GLData[gl].guestName} -{" "}
										{participant.gl[gl]
											? participant.gl[gl] == 2
												? "Attended"
												: "Registered"
											: "Not Registered"}
										<br />
										{/* {participant.gl[gl] == 2} */}
										{participant.gl[gl] != 2 && !(role < 2) && (
											<Button
												variant="primary"
												onClick={() => {
													glAttendance(gl);
												}}
											>
												Mark Attendance
											</Button>
										)}
									</p>
								</div>
							);
						})}
					</div>

					{participant.certificates && (
						<>
							<br />
							<h3>Certificates</h3>
							<ul>
								{participant.certificates.map((cert) => (
									<li>
										<a
											href={"https://nssc.in/certificate/" + cert.cid}
											target="_blank"
										>
											{cert.issuedFor.name === "workshop"
												? "Workshop "
												: "Fest Participation "}
											Certificate
										</a>
									</li>
								))}
							</ul>
						</>
					)}
				</div>
			)}
		</Container>
	);
}
