import { useState } from "react";
import { callBackend } from "../components/callBackend";
import { Button, Container } from "react-bootstrap";
import DataTable from "../components/DataTable";
import EventsData from "../data/events.json";

export default function Winners() {
	const [event, setEvent] = useState(0);
	const [pid, setPid] = useState("");
	const [position, setPosition] = useState("");
	const [partData, setPartData] = useState([]);

	const [tableControls, setTableControls] = useState({
		page: 1,
		length: 20,
		sortField: "_id",
		sortDir: 1,
	});

	const getPartData = async (event) => {
		if (!localStorage.getItem("nssc-admin-token")) {
			window.location.href = "/login";
		}

		callBackend({
			method: "GET",
			endpoint: "/admin/event/winner/" + event,
			onSuccess: (res) => {
				setPartData(res.data);
			},
		});
	};

	const columns = {
		pid: "PID",
		name: "Name",
		college: "College/School",
		position: "Position",
		cid: "Certificate",
	};

	return (
		<Container
			style={{
				marginTop: "20px",
				marginBottom: "20px",
			}}
		>
			<h1>Winners</h1>
			<div>
				<select
					value={event}
					onChange={(e) => {
						setEvent(e.target.value);
						if (e.target.value != 0) {
							getPartData(e.target.value);
						}
					}}
				>
					<option value={0}>Select Event</option>
					{Object.keys(EventsData).map((val) => {
						return (
							<option value={val} key={val}>
								{EventsData[val].name}
							</option>
						);
					})}
				</select>
				&nbsp;&nbsp;Value:&nbsp;&nbsp;
				<input
					type="text"
					placeholder="PID"
					onChange={(e) => {
						setPid(e.target.value);
					}}
				/>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<select
					value={position}
					onChange={(e) => {
						setPosition(e.target.value);
					}}
				>
					<option value={""}>Select Position</option>
					<option value={"1st"}>1st</option>
					<option value={"2nd"}>2nd</option>
					<option value={"3rd"}>3rd</option>
				</select>
				&nbsp;&nbsp;Value:&nbsp;&nbsp;
				<Button
					variant="primary"
					onClick={() => {
						if (event === 0 || pid === "" || position === "") {
							alert("Please fill all fields");
							return;
						}
						callBackend({
							method: "POST",
							endpoint: "/admin/event/winner/" + event,
							data: { pid: pid, position: position },
							onSuccess: (res) => {
								getPartData(event);
							},
						});
					}}
				>
					Mark Winner
				</Button>
			</div>
			<br />
			<DataTable
				columns={columns}
				data={partData}
				tableControls={tableControls}
				setTableControls={setTableControls}
			/>
		</Container>
	);
}
