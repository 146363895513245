import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useZxing } from "react-zxing";
import { callBackend } from "../components/callBackend";
import GLData from "../data/gl.json";
import EventData from "../data/events.json";
import DataTable from "../components/DataTable";

export default function Attendance({ glOrEvent }) {
	const { gl } = useParams();
	if (glOrEvent === "gl") {
		if (gl == 3 || gl == 1) {
			alert("Attendance for this GL is not available");
			return;
		}
	}

	const { ref } = useZxing({
		onDecodeResult(result) {
			document.getElementsByName("pid")[0].value = result.getText();
			glAttendance();
		},
	});

	const [partData, setPartData] = useState([]);

	const [tableControls, setTableControls] = useState({
		page: 1,
		length: 20,
		sortField: "_id",
		sortDir: 1,
	});

	const getPartData = async (event) => {
		if (glOrEvent === "gl") return;

		if (!localStorage.getItem("nssc-admin-token")) {
			window.location.href = "/login";
		}

		callBackend({
			method: "GET",
			endpoint: "/admin/event/attendance/" + event,
			onSuccess: (res) => {
				setPartData(res.data);
			},
		});
	};

	useEffect(() => {
		getPartData(gl);
	}, [gl]);

	const columns = {
		pid: "PID",
		name: "Name",
		college: "College/School",
		cid: "Certificate",
	};

	const glAttendance = async () => {
		if (confirm("Are you sure you want to mark attendance?")) {
			callBackend({
				method: "POST",
				endpoint:
					glOrEvent === "gl"
						? "/admin/gl/" + gl
						: "/admin/event/attendance/" + gl,
				data: {
					pid: document.getElementsByName("pid")[0].value,
				},
				onSuccess: (res) => {
					document.getElementsByName("pid")[0].value = "";
					getPartData(gl);
				},
			});
		}
	};

	return (
		<Container
			style={{
				marginTop: "20px",
				marginBottom: "20px",
			}}
		>
			<h1 className="text-center">
				<u>
					Attendance for{" "}
					{glOrEvent === "gl"
						? GLData[gl].guestName
						: EventData[gl].name}
				</u>
			</h1>
			<br />
			<center>
				<div
					style={{
						gap: "10px",
						display: "flex",
						justifyContent: "center",
					}}
				>
					<input type="text" placeholder="Enter PID" name="pid" />
					<button className="btn btn-primary" onClick={glAttendance}>
						Submit
					</button>
				</div>
				<br />
				<video
					ref={ref}
					style={{
						width: "100%",
						height: "auto",
						maxWidth: "500px",
						maxHeight: "300px",
					}}
				/>
			</center>
			{glOrEvent !== "gl" && (
				<DataTable
					columns={columns}
					data={partData}
					count={partData.length}
					tableControls={tableControls}
					setTableControls={setTableControls}
				/>
			)}
		</Container>
	);
}
